import React, { useEffect, useState } from "react";
import Login from "./Pages/Login/Login";
import Register from './Pages/Register/Register'
import {
  BrowserRouter as Router,
  Route, Routes, Link
} from "react-router-dom";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard"
import Loading from "./Components/Loading/Loading";
import { PublicRoute, UnAuthRoute, } from "./Routing/PublicRoute";
import { AdminRoute, GraderRoute, PrivateRoute, UserRoute } from "./Routing/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import Users from "./Pages/Users/User";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import moment from 'moment';
import User from "./Pages/UserPage/User";
import Images from "./Pages/Images/ImagesInfoTable/ImagesInfoTable";
import UploadImages from "./Pages/Images/UploadImages/UploadImages";
import HomePage from "./Pages/HomePage/HomePage";
import Grade from "./Pages/Grade/Grade";
import GradedImages from "./Pages/Images/GradedImages/GradedImages";
import GradingByUser from "./Pages/Images/GradedImages/GradingByUser";


function App() {

  var userData = useSelector(state => state.user)
  const dispatch = useDispatch();
  const token = localStorage.getItem('token')




  const [authentication, setAuthState] = useState({
    authenticated: false, //whether the user is allowed to access the protected routes
    //initialized: true, //if firebase is still being initialized
    loading: true,
  });

  const checkUser = async () => {

    const token = localStorage.getItem('token')

    if (token) {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/userData`, {

        headers: {
          'x-access-token': localStorage.getItem('token'),
        },

      })
      const data = await response.json()
      
      if (data.user === undefined || data.user === null) {
        localStorage.removeItem('token')
        setAuthState({
          ...authentication,
          authenticated: false, //the user is
          loading: false,
        });
        return
      }
      

      if (data.user.approved === 'true') {


        if (data.status === 'ok') {
          setAuthState({
            ...authentication,
            authenticated: true, //the user is now authenticated
            loading: false,

          });

          dispatch({ type: 'Fetch_User_Success', email: data.user.email, uid: data.user._id, name: data.user.name, role: data.user.role })

        }
      } else {
        setAuthState({
          ...authentication,
          authenticated: false, //the user is now authenticated
          loading: false,

        });
      }

    }
  }

  //  check jwt toke
  useEffect(() => {

    if (token) {

      checkUser()
    } else {
      setAuthState({
        ...authentication,
        authenticated: false, //the user is now authenticated
        loading: false,

      });
    }

  }, [token])
  //  check jwt toke
  if (authentication.loading) {
    return (<Loading />)
  }

  return (
    <Router>

      <Routes>

        <Route path="/" element={<GraderRoute auth={authentication.authenticated}><HomePage /></GraderRoute>} />
        <Route path="/admin" element={<AdminRoute auth={authentication.authenticated}><AdminDashboard /></AdminRoute>} />
        <Route path='/admin/users' element={<AdminRoute auth={authentication.authenticated}><Users /></AdminRoute>} />
        <Route path='/admin/images' element={<AdminRoute auth={authentication.authenticated}><Images /></AdminRoute>} />
        <Route path='/admin/graded_images' element={<AdminRoute auth={authentication.authenticated}><GradedImages/></AdminRoute>} />
        <Route path='/admin/graded_images/user' element={<AdminRoute auth={authentication.authenticated}><GradingByUser/></AdminRoute>} />
        <Route path='/grade/:id' element={<GraderRoute auth={authentication.authenticated}><Grade /></GraderRoute>} />
        <Route path='/admin/images/upload' element={<AdminRoute auth={authentication.authenticated}><UploadImages /></AdminRoute>} />
        <Route path="/login" element={<UnAuthRoute auth={authentication.authenticated}> <Login /></UnAuthRoute>} />
        <Route path="/register" element={<UnAuthRoute auth={authentication.authenticated}> <Register /></UnAuthRoute>} />
        <Route path="/user" element={<UserRoute auth={authentication.authenticated}> <User /></UserRoute>} />

        <Route path="*" element={<PageNotFound />} />
  
      </Routes>

    </Router >



  );
}

export default App;
