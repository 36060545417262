import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function GradeNav() {

  const imageData = useSelector(state => state.imageData)
  const images = useSelector(state => state.images)
  const user = useSelector(state => state.user)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()
  const { gradingImageData, data } = imageData

  const navigate = useNavigate()

  const saveGrading = async () => {
    console.log(gradingImageData)
    let errors = {}
    //let label_obj = {}

    Object.entries(gradingImageData).map((data, index) => {

      if (data[1] === "") {
        errors[data[0]] = true
      }
    })

    dispatch({
      type: "SET_ERRORS",
      data: errors
    })
    if (Object.values(errors).length > 0) {
      toast.warn('Please Fill All Values!', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return
    }


    storeFromData()


    if (Object.values(errors).length > 0) {
      console.log("plz select all the questions")
    }
    else {
      console.log("all selected")
    }
  }


  const storeFromData = async () => {

    const checkResponse = await checkFormData()

    //console.log(checkResponse[user.uid])
    //console.log(checkResponse[user.uid].data)
    if (checkResponse === null || checkResponse[1] === undefined) {
      //console.log("nulll")
      setIsSubmitting(true)
      console.log("Will be submitted")
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/imageGradedData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          imageId: data._id,
          userId: user.uid,
          // create_time: new Date(),
          // update_time: new Date(),
          gradingImageData
        })
      })
      
      const response_data = await response.json()
      
      setIsSubmitting(false)


      if (response_data.status === "ok") {
        toast.success('Gradings Submitted Successfully!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });

        // Navigate to next image
        navigate(`/grade/${response_data.nextImage}`)
      }


    }

    else {
      setIsSubmitting(true)
      
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/updateImageGradedData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          label_image_id: checkResponse[0]._id,
          image_index: checkResponse[1],
          imageId: data._id,
          userId: user.uid,
          // create_time: checkResponse[0].create_time,
          // update_time: new Date(),
          gradingImageData
        })
      })
      const response_data = await response.json()
      if (response_data.status === "ok") {
        toast.success('Gradings Updated Successfully!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
      setIsSubmitting(false)
    }

  }

  const checkFormData = () => {
    if (imageData.data.gradings.length === 0) {
      return null
    }
    let image_index
    let data_obj = {}
    imageData.data.gradings.map((item, index) => {
      if (item.userId === user.uid) {
        image_index = index
        data_obj = item
      }


    })
    return [data_obj, image_index]
  }

  useEffect(() => {
    // On Press Enter
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        saveGrading()
        //console.log("enter")
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [gradingImageData])





  return (
    <div className='bg-[#c8c8c8] flex items-center justify-end p-1' style={{ fontFamily: 'sans-serif', padding: '6px' }}>
      <button onClick={() => saveGrading()} style={{ borderRadius: "3px", fontSize: '14px', padding: "7px 14px" }} className='mr-2 bg-[#1976d2] text-xs text-white rounded-md shadow-lg p-1 flex justify-center items-center pr-2'>
        <span className='flex justify-center items-center mr-1'>
          <iconify-icon height='1.5em' icon="ic:round-save"></iconify-icon>
        </span>
        {isSubmitting ? "Saving..." : "SAVE"}
      </button>
      <ToastContainer />
    </div>
  )
}

export default GradeNav