
import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux';

const useStyles = makeStyles({

});


function Graphs() {

  const classes = useStyles();
  const [seriesData, setSeriesData] = useState([])
  const [DaysName,setDaysName] = useState([])
  var user = useSelector(state => state.user)

  const getBarChartData = async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/barChartData`, {

      headers: {
        'x-access-token': localStorage.getItem('token'),
      },

    })
    let response_data = await response.json()
    //console.log(response_data)
    
    response_data.data.map((item) => {
      //console.log(item.data.reverse()) 
      item.data.reverse()     
    })
    setSeriesData(response_data.data)
    setDaysName(response_data.daysName)
    

  }
  //console.log(seriesData)
  

  useEffect(() => {
    getBarChartData()
  }, [])

  const state = {
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: DaysName,

      }
    },
    series: seriesData,
    // series: 
    // [
    //   {
    //     name: "series-1",
    //     data: [10, 20, 30, 40, 50, 60, 70]
    //   }
    //   ,
    //   {
    //     name: "s-1",
    //     data: [10, 20, 6, 40, 80, 60, 30]
    //   }
    // ]
  };


  return (

    <div className="p-4 w-full max-w-[1240px] sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <div className="flex justify-between items-center">
        <h5 className="text-[#7A8289] text-lg font-bold leading-none dark:text-white uppercase">this week grading insights</h5>
        <div className="text-sm font-medium text-[#616A73] hover:underline dark:text-blue-500">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path></svg>
        </div>
      </div>
      <div className='mt-5 overflow-x-auto relative'>
        <Chart
          options={state.options}
          series={state.series}
          type="area"
          width="500"
          height="320"
        />

      </div>
    </div>


  )
}

export default Graphs