import React from 'react'
import { Link } from 'react-router-dom'

function PageNotFound() {
    return (
        <div className='bg-[#F4F6F6] h-screen w-full flex justify-center items-center'>
            <div className='bg-[#EEEEEE] max-w-[1240px] mx-auto p-16'>
                <h2 className='text-[#414546] font-bold text-2xl md:text-4xl mb-5'>Page Not Found</h2>
                <p className='text-[#687070] text-xs md:text-sm max-w-sm md:max-w-md mb-5'>Looks like you've followed a broken link or entered a URL that doesn't exist on this site.</p>
                <Link to='/admin'>
                    <div className='text-[#59CBC2] flex  items-center text-xs md:text-sm'>
                        <iconify-icon icon="cil:arrow-left"></iconify-icon>
                        <p className='ml-1'>Back to our site</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default PageNotFound