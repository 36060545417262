import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import Pagination from '../Pagination/Pagination';

//-------------pagination-----------
const LIMIT = 20;

const totalPagesCalculator = (total, limit) => {

    const pages = [];

    for (let x = 1; x <= Math.ceil(total / limit); x++) {
        pages.push(x);
    }
    //console.log(pages)

    return pages;
}
//-------------pagination-----------



function ImagesTable() {
    const [ImageData, setImagesData] = useState([])
    //-------------pagination-----------


    const [activePage, setActivePage] = useState(1);
    const [totalImages, setTotalImages] = useState(0);
    const indexOfLast_Image = activePage * LIMIT;
    const indexOfFirst_Image = indexOfLast_Image - LIMIT;
  



    const getImages = async (option) => {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/getImages?type=${option}`, {
            headers: {
                'x-access-token': localStorage.getItem('token'),
                page: activePage,
                size: LIMIT
            },
        })
        const data = await response.json()

        //console.log(data)

        const myarr = []
        data.Images.forEach((doc) => {

            myarr.push(doc)

        })
        setTotalImages(data.total)
        setImagesData(myarr)
    }

    useEffect(() => {
        getImages('All')
    }, [activePage])


    return (
        <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className=" text-[#7A8289] bg-gray-50 dark:bg-gray-700 ">
                    <tr>
                        <th scope="col" className="py-3 px-3">

                        </th>
                        <th scope="col" className="py-3 px-3">
                            Original Name
                        </th>
                        <th scope="col" className="py-3 px-3">
                            File Name
                        </th>
                        <th scope="col" className="py-3 px-3">
                            Gradings
                        </th>
                        <th scope="col" className="py-3 px-3">
                            Date
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {ImageData.map((item, index) => {
                        return (
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" >
                                <td className="py-4 px-3 ">
                                    {index + 1 + indexOfFirst_Image}
                                </td>
                                <td className="py-4 px-3">
                                    {item.originalName}
                                </td>
                                <td className="py-4 px-3">
                                    {item.fileName}
                                </td>
                                <td className="py-4 px-3">
                                    {item.gradings.length}
                                </td>
                                <td className="py-4 px-3">
                                    {moment(item.date).format("DD/MM/YYYY")}
                                </td>

                            </tr>
                        )
                    })
                    }
                </tbody>
            </table>

            
            <div className="flex justify-end mt-5">

                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    {activePage !== 1 && <div onClick={() => setActivePage(activePage - 1)} className="hover:text-white hover:bg-[#727CF4] cursor-pointer relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 focus:z-20">
                        <span className="sr-only">Previous</span>

                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                        </svg>
                    </div>
                    }

                    <ul>
                        {totalPagesCalculator(totalImages, LIMIT).map(page => (
                            <li key={page} onClick={() => setActivePage(page)} className={`${activePage === page ? 'bg-[#727CF4] text-white' : ''} hover:text-white hover:bg-[#727CF4] cursor-pointer relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500  focus:z-20`}>{page}</li>
                        ))}
                    </ul>


                    {activePage !== totalPagesCalculator(totalImages, LIMIT).length && <div onClick={() => setActivePage(activePage + 1)} className="hover:text-white hover:bg-[#727CF4] cursor-pointer relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 focus:z-20">
                        <span className="sr-only">Next</span>

                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                        </svg>
                    </div>
                    }
                </nav>

            </div>


        </>
    )
}

export default ImagesTable