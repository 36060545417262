const initState = {
    data: {},
    radio_options: [
        {
            "value": "1",
            "label": "Strongly Disagree"
        },
        {
            "value": "2",
            "label": "Disagree"
        },
        {
            "value": "3",
            "label": "Neither agree or disagree"
        },
        {
            "value": "4",
            "label": "Agree"
        },
        {
            "value": "5",
            "label": "Strongly agree"
        },
    ],
    gradingImageData: {},
    gradingErrors: {},
    graded: false
   

}

const imageReducer = (state = initState, action) => {
    //console.log(action)
    switch (action.type) {
        case "Fetch_Image_Data":

            return {
                ...state,
                data: action.data,
                gradingImageData: action.gradingData,
                graded: action.graded
            }
        case "Fetch_Grading_Image_Data":
            
            return {
                ...state,
                gradingImageData: action.gradingImageData
            };

        case 'ON_GRADE_CHANGE':
            
            return {
                ...state,
                gradingImageData: {
                    ...state.gradingImageData,
                    [action.key]: action.value
                },
                gradingErrors: {
                    ...state.gradingErrors,
                    [action.key]: false
                }
            }
        case "SET_ERRORS":
            return {
                ...state,
                gradingErrors: action.data
            }
        case "FETCH_FORM_DATA":
            return {
                ...state,
                evaluationFormData: action.evaluationFormData
            }


        default:
            return state
    }
}
export default imageReducer