import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import Dashboard from '../../../Components/Dashboard/Dashboard'
import Header from '../../../Components/Header/Header'
import moment from 'moment'
import { useNavigate } from 'react-router'
import Loading from '../../../Components/Loading/Loading'

//-------------pagination-----------
const LIMIT = 1;

const totalPagesCalculator = (total, limit) => {

  const pages = [];

  for (let x = 1; x <= Math.ceil(total / limit); x++) {
    pages.push(x);
  }

  return pages;
}
//-------------pagination-----------

function GradedImages() {
  const myRef = useRef('Graded Images')
  const [Gradings, setGradings] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();


  //-------------pagination-----------


  const [activePage, setActivePage] = useState(1);
  const [totalGradings, settotalGradings] = useState(0);
  const indexOfLastGrading = activePage * LIMIT;
  const indexOfFirstGrading = indexOfLastGrading - LIMIT;
  //console.log(indexOfFirstGrading,indexOfLastGrading)

  //-------------pagination-----------


  const getGradings = async () => {
    setLoading(true)
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/gradings`, {

      headers: {
        'x-access-token': localStorage.getItem('token'),
        page: activePage,
        size: LIMIT
      },

    })
    //console.log("response is",response)
    let response_data = await response.json()
    //console.log(response_data)
    //setUserId(response_data.user_id)   
    setGradings(response_data)
    setLoading(false)
  }



  useEffect(() => {
    getGradings()
  }, [])
  if (loading) {
    return (<Loading />)
  }

  return (
    <div className='bg-[#FAFBFE] w-full h-screen'>
      <Header ref={myRef}>
        <Dashboard />

        <div className='bg-[#FAFBFE] w-full h-screen'>
          <div className='border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 max-w-[1240px] mx-auto'>
            <div className=" max-w-[1240px] mx-auto bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <div className="flex justify-between items-center mb-4 px-4 py-3 md:px-0 md:py-0">
                <h5 className="text-[#7A8289] text-lg font-bold leading-none  dark:text-white">Gradings</h5>
                <div className="dark:text-blue-500">
                  <span onClick={() => navigate('/admin/graded_images/user')} className='text-[#727CF4] cursor-pointer text-lg font-medium'>By User</span>
                </div>
              </div>

              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className=" text-[#7A8289] bg-gray-50 dark:bg-gray-700 ">
                  <tr>
                    <th scope="col" className="py-3 px-3">

                    </th>
                    <th scope="col" className="py-3 px-3">
                      User
                    </th>
                    <th scope="col" className="py-3 px-3">
                      Original Name
                    </th>

                    <th scope="col" className="py-3 px-3">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {Gradings.map((item, index) => {
                    //console.log(index)
                    // return (
                    //   item.map((sub_img, sub_index) => {
                    //     console.log("sub_index",sub_index)
                    return (
                      <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" >
                        <td className="py-4 px-3 ">
                          {index + 1}
                        </td>
                        <td className="py-4 px-3">
                          {item.name}
                        </td>
                        <td className="py-4 px-3">
                          {item.imageName}
                        </td>


                        <td className="py-4 px-3">
                          {moment(item.update_time).format("DD/MM/YYYY")}
                        </td>

                      </tr>
                    )
                    //   })
                    // )
                  })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Header>
    </div>
  )
}

export default GradedImages