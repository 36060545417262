import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Dashboard from '../Dashboard/Dashboard';




function Navbar() {
    const search = useLocation().search;
    const type = new URLSearchParams(search).get('type') || 'double';


    var user = useSelector(state => state.user)

    const [showMenu, setShowMenu] = useState(true)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const LogoutHandler = () => {

        dispatch({ type: "USER_CLEAR" })
        localStorage.removeItem('token')

        return navigate("/login")

    }

    const handleNav = () => {
        setShowMenu(!showMenu)
    }



    return (

        <div className=''>
            <nav className="bg-[#FFFFFF] shadow-md">
                <div className="w-full px-2 sm:px-6 lg:px-8">
                    <div className="relative flex items-center justify-between h-16">
                        <div className="flex-1 flex items-center justify-start">
                            <div onClick={() => navigate("/")} className="flex-shrink-0 flex items-center cursor-pointer">
                                <img className="block h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=500" alt="Workflow" />
                            </div>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center md:pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                            <Link to='/'>
                                <button className={`${type === "double" ? "sm:bg-[#462d2d] sm:text-white" : "sm:bg-[#bbcccc] sm:text-black"
                                    } hover:bg-[#462d2d] hover:text-white font-bold py-1 md:px-4 md:mx-1 rounded-full`}>
                                    Side by Side
                                </button>
                            </Link>
                            <Link to='/?type=single'>
                                <button className={`${type === "single" ? "sm:bg-[#462d2d] sm:text-white" : "sm:bg-[#bbcccc] sm:text-black"
                                    } hover:bg-[#462d2d] hover:text-white font-bold py-1 px-2 md:mx-4 rounded-full`}>
                                    Single
                                </button>
                            </Link>
                            {/* <!-- Profile dropdown --> */}
                            <div>
                                <p className='text-sm font-bold text-[#444]' style={{
                                    fontFamily: 'sans-serif'
                                }}>{user.name}</p>
                            </div>
                            <div className="ml-3 relative">
                                <div>
                                    <button onClick={handleNav} className="sm:bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">

                                        <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                                    </button>
                                </div>

                                <div className={!showMenu ? 'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10' : "hidden"}>

                                    <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">Your Profile</div>
                                    <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">Settings</div>
                                    <div onClick={() => LogoutHandler()} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">Sign out</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar