import React, { useRef } from 'react'
import Dashboard from '../../Components/Dashboard/Dashboard'
import Header from '../../Components/Header/Header'
import UsersTable from '../../Components/UsersTable/UsersTable'

function User() {

    

    // const [showMenu, setShowMenu] = useState(true)

    const myRef = useRef('Users')

    // const handleMenu = () => {
    //     setShowMenu(!showMenu)
    // }

    return (
        <div className='bg-[#FAFBFE] w-full h-screen'>
            <Header ref={myRef}>
                <Dashboard />
                <div className='bg-[#FAFBFE] w-full h-screen'>
                    <div className='border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 max-w-[1240px] mx-auto'>
                        <div className=" max-w-[1240px] mx-auto bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                            <div className="flex justify-between items-center mb-4 px-4 py-3 md:px-0 md:py-0">
                                <h5 className="text-[#7A8289] text-lg font-bold leading-none  dark:text-white">Users</h5>
                                <div className="text-sm font-medium text-[#616A73] hover:underline dark:text-blue-500">
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                                        </path>
                                    </svg>
                                </div>
                            </div>

                            <div className="overflow-x-auto relative">
                                <UsersTable/>
                                {/* <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className=" text-[#7A8289] bg-gray-50 dark:bg-gray-700 ">
                                        <tr>
                                            <th scope="col" className="py-3 px-3">

                                            </th>
                                            <th scope="col" className="py-3 px-3">
                                                Name
                                            </th>
                                            <th scope="col" className="py-3 px-3">
                                                Email
                                            </th>
                                            <th scope="col" className="py-3 px-3">
                                                Join Date
                                            </th>
                                            <th scope="col" className="py-3 px-3">
                                                Admin?
                                            </th>
                                            <th scope="col" className="py-3 px-3">
                                                Approved?
                                            </th>
                                            <th scope="col" className="py-3 px-3">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="py-4 px-3 ">
                                                1
                                            </td>
                                            <td className="py-4 px-3">
                                                Ali
                                            </td>
                                            <td className="py-4 px-3">
                                                Ali@gmail.com
                                            </td>
                                            <td className="py-4 px-3">
                                                05/08/22
                                            </td>
                                            <td className="">

                                                <button onClick={handleMenu} className="text-[#7A8289] border focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Select Role <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg></button>
                                                
                                                <div className={!showMenu ? "z-10 w-[125px] bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 block" : "hidden"} >
                                                    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" >
                                                        <li>
                                                            <div className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Admin</div>
                                                        </li>
                                                        <li>
                                                            <div className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Grader</div>
                                                        </li>

                                                    </ul>
                                                </div>


                                            </td>
                                            <td className="py-4 px-3">
                                                <label  className="inline-flex relative items-center cursor-pointer">
                                                    <input type="checkbox" value="" id="small-toggle" className="sr-only peer" />
                                                    <div className="w-10 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                                </label>
                                            </td>
                                            <td className="py-4 px-3">

                                                <iconify-icon style={{ color: '#FA5C7C' }} icon="ant-design:delete-filled" height='20'></iconify-icon>
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="py-4 px-3 ">
                                                2
                                            </td>
                                            <td className="py-4 px-3">
                                                Ali
                                            </td>
                                            <td className="py-4 px-3">
                                                Ali@gmail.com
                                            </td>
                                            <td className="py-4 px-3">
                                                05/08/22
                                            </td>
                                            <td className="">

                                                <button onClick={handleMenu} className="text-[#7A8289] border focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Select Role <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg></button>
                                                
                                                <div className={!showMenu ? "z-10 w-[125px] bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 block" : "hidden"} >
                                                    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" >
                                                        <li>
                                                            <div className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Admin</div>
                                                        </li>
                                                        <li>
                                                            <div className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Grader</div>
                                                        </li>

                                                    </ul>
                                                </div>


                                            </td>
                                            <td className="py-4 px-3">
                                                <label  className="inline-flex relative items-center cursor-pointer">
                                                    <input type="checkbox" value="" id="small-toggle" className="sr-only peer" />
                                                    <div className="w-10 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                                </label>
                                            </td>
                                            <td className="py-4 px-3">

                                                <iconify-icon style={{ color: '#FA5C7C' }} icon="ant-design:delete-filled" height='20'></iconify-icon>
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="py-4 px-3 ">
                                                3
                                            </td>
                                            <td className="py-4 px-3">
                                                Ali
                                            </td>
                                            <td className="py-4 px-3">
                                                Ali@gmail.com
                                            </td>
                                            <td className="py-4 px-3">
                                                05/08/22
                                            </td>
                                            <td className="">

                                                <button onClick={handleMenu} className="text-[#7A8289] border focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Select Role <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg></button>
                                                
                                                <div className={!showMenu ? "z-10 w-[125px] bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 block" : "hidden"} >
                                                    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" >
                                                        <li>
                                                            <div className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Admin</div>
                                                        </li>
                                                        <li>
                                                            <div className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Grader</div>
                                                        </li>

                                                    </ul>
                                                </div>


                                            </td>
                                            <td className="py-4 px-3">
                                                <label className="inline-flex relative items-center cursor-pointer">
                                                    <input type="checkbox" value="" id="small-toggle" className="sr-only peer" />
                                                    <div className="w-10 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                                </label>
                                            </td>
                                            <td className="py-4 px-3">

                                                <iconify-icon style={{ color: '#FA5C7C' }} icon="ant-design:delete-filled" height='20'></iconify-icon>
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="py-4 px-3 ">
                                                4
                                            </td>
                                            <td className="py-4 px-3">
                                                Ali
                                            </td>
                                            <td className="py-4 px-3">
                                                Ali@gmail.com
                                            </td>
                                            <td className="py-4 px-3">
                                                05/08/22
                                            </td>
                                            <td className="">

                                                <button onClick={handleMenu} className="text-[#7A8289] border focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Select Role <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg></button>
                                                
                                                <div className={!showMenu ? "z-10 w-[125px] bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 block" : "hidden"} >
                                                    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" >
                                                        <li>
                                                            <div className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Admin</div>
                                                        </li>
                                                        <li>
                                                            <div className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Grader</div>
                                                        </li>

                                                    </ul>
                                                </div>


                                            </td>
                                            <td className="py-4 px-3">
                                                <label  className="inline-flex relative items-center cursor-pointer">
                                                    <input type="checkbox" value="" id="small-toggle" className="sr-only peer" />
                                                    <div className="w-10 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                                </label>
                                            </td>
                                            <td className="py-4 px-3">

                                                <iconify-icon style={{ color: '#FA5C7C' }} icon="ant-design:delete-filled" height='20'></iconify-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Header>
        </div >
    )
}

export default User