export const data = {
    "double": [
        {
            "title": '1. Fungus resembling structures appear similar in image (a) and image (b)',
            "id": "resolution"
        },
        {
            "title": '2. The STAINING of image (a) is similar to the coloring of image (b).',
            "id": "sharpness"
        },
        {
            "title": '3. The RESOLUTION of image (a) is similar to the resolution of image (b).',
            "id": "contrast"
        },
        {
            "title": '4. The SHARPNESS of image (a) is similar to the sharpness of image (b).',
            "id": "brightness"
        },
        {
            "title": '5. The CONTRAST of image (a) is similar to the contrast of image (b).',
            "id": "Uniform_illumination"
        },
        {
            "title": '6. ARTIFACTS are similar between image (a) and image (b).',
            "id": "artifacts"
        },
    ],
    "single": [
        {
            "title": '1. The STAINING of the image is:',
            "id": "color"
        },
        {
            "title": '2. The RESOLUTION of the image is:',
            "id": "resolution"
        },
        {
            "title": '3. The SHARPNESS of the image is:',
            "id": "sharpness"
        },
        {
            "title": '4. The CONTRAST of the image is:',
            "id": "contrast"
        },
        {
            "title": '5. Do you see fungal hyphae and spores in this image?',
            "id": "brightness"
        },
        {
            "title": '6. Are there significant ARTIFACTS that are impairing the reading of the image:',
            "id": "Uniform_illumination"
        },
    ]
}

