
import { useSelector } from "react-redux";
import { Navigate } from "react-router";


// const PrivateRoute = ({ auth, children }) => {
//   return auth ? children : <Navigate to="/login" />
// };

const AdminRoute = ({ auth, children }) => {
  var user = useSelector(state => state.user)
  console.log("admin route", user.role)
  if (!auth) {
    return <Navigate to="/login" />
  } else {
    if (user.role === "admin") {
      return children
    }else if (user.role === "grader") {
      return  <Navigate to="/" />
    }
  }


};
const UserRoute = ({ auth, children }) => {
  var user = useSelector(state => state.user)
  
  if (!auth) {
    return <Navigate to="/login" />
  } else {
    if (user.role === "user") {
      return children
    }
  }
};

const GraderRoute = ({ auth, children }) => {
  var user = useSelector(state => state.user)
  
  if (!auth) {
    return <Navigate to="/login" />
  } else {
    if (user.role === "grader") {
      return children
    }else if (user.role === "admin") {
      return  <Navigate to="/admin" />
    }

    //return  <Navigate to="/" />
  }

  //  // return auth && user.role==="admin" ? children : <Navigate to="/" />
};



export { AdminRoute, UserRoute, GraderRoute }


// const PrivateRoute = ({ auth, children }) => {
//   var user = useSelector(state => state.user)
//   console.log("admin route")
//   if (user.role === "admin") {
//     return auth ? children : <Navigate to='/login' />
//   }
// };
// const UserRoute = ({ auth, children }) => {
//   var user = useSelector(state => state.user)
//   console.log("user route")
//   if (user.role === "user") {
//     return auth ? children : <Navigate to='/login' />
//   }
// };



//  export { PrivateRoute, UserRoute }