import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


import Dashboard from '../../Components/Dashboard/Dashboard'
import Header from '../../Components/Header/Header'
import Loading from '../../Components/Loading/Loading'
import Pagination from '../../Components/Pagination/Pagination'
import {
    useParams, useLocation
} from "react-router-dom";
//------------for backend pagination--------------
// const LIMIT = 20;

// const totalPagesCalculator = (total, limit) => {
//     const pages = [];
//     for (let x = 1; x <= parseInt(total) / limit; x++) {
//         pages.push(x);
//     }

//     return pages;
// }
//------------for pagination--------------

function HomePage() {



    const search = useLocation().search;
    const type = new URLSearchParams(search).get('type') || 'double';

    const myRef = useRef('Home')
    const navigate = useNavigate()
    const user = useSelector(state => state.user)

    const imagesData = useSelector(state => state.images);
    const ImageData = imagesData.images;

    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const imagesPerPage = 20;
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const [imageCount, setImageCount] = useState(imagesPerPage);
    const currentImages = ImageData.slice(indexOfFirstImage, indexOfLastImage);

    const [showMenu, setShowMenu] = useState(true)
    const [fetchLoading, setFetchLoading] = useState(false)
    const [hideMenu, setHideMenu] = useState(true)

    //------------for backend pagination--------------

    // const [activePage, setActivePage] = useState(1);
    // const [totalImages, setTotalImages] = useState(0);
    // const indexOfLast_Image = activePage * LIMIT;
    // const indexOfFirst_Image = indexOfLast_Image - LIMIT;

    //------------for pagination--------------



    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
        setImageCount(imagesPerPage * pageNumber)
    };

    const getPrevious = () => {
        setCurrentPage(currentPage - 1)
        setImageCount(imagesPerPage * (currentPage - 1))
    };

    const getNext = () => {
        setCurrentPage(currentPage + 1)
        setImageCount(imagesPerPage * (currentPage + 1))
    };


    const handleNav = () => {
        setShowMenu(!showMenu)

    }




    // Filters
    // type => all, graded, not-graded

    // const getImages = async () => {
    //     const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/getImages`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //     const data = await response.json()

    //     // console.log(data)


    //     dispatch({ type: "Fetch_Images_Data", images: data.Images })
    // }

    useEffect(() => {
        handleClick('Not Graded')
    }, [type])
    // console.log(data)
    const handleClick = async (option) => {

        setCurrentPage(1)
        setFetchLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/getImages?type=${option}&imtype=${type}`,

            {
                headers: {
                    'x-access-token': localStorage.getItem('token'),
                    //--------pagination from backend----------

                    // page: activePage,
                    // size: LIMIT,

                    //--------pagination from backend---------
                },



            },
        )
        const data = await response.json()
        //console.log(data)
        //--------pagination from backend----------

        // setTotalImages(data.total)

        //--------pagination from backend----------
        dispatch({ type: "Fetch_Images_Data", images: data.Images })
        setFetchLoading(false)
    }


    if (fetchLoading) {

        return (<Loading />)
    }

    return (
        <div className='bg-[#FAFBFE] w-full h-screen'>
            <Header ref={myRef} >
                <div className='mb-4 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 max-w-[1240px] mx-auto bg-[#FAFBFE] mt-5'>

                    <div className="container flex flex-wrap justify-between items-center mx-auto">
                        <div className="flex items-center">
                            <div className="cursor-pointer self-center md:text-xl sm:text-sm text-[#657C92] font-semibold whitespace-nowrap dark:text-white">Dashboard <span className="sm:text-sm" style={{ color: "#09F" }}>({type === "double" ? 'Side By Side' : 'Single'})</span> <span className='text-sm'> {`(${imagesData.images.length} images)`}</span> </div>
                        </div>
                        <div className="flex md:order-2">
                            <div className="hidden  md:flex">

                                {/* <input type="text" id="search-navbar" className="block p-2 pl-3 w-full text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 sm:text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                                <div className="flex inset-y-0 left-0 items-center cursor-pointer bg-[#727CF4] px-2 rounded-r-lg">
                                    <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" ></path></svg>
                                </div>
                                <div className="flex inset-y-0 left-0 items-center cursor-pointer bg-[#727CF4] px-2 rounded-lg ml-2 shadow-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                                        <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                    </svg>
                                </div> */}

                                {/* <div className="ml-1" >
                                    <div>
                                        <button onClick={handleNav} className="text-white bg-[#727CF4] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" >

                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
                                        </button>
                                    </div>

                                    <div onClick={handleNav} className={!showMenu ? 'origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none' : "hidden"} >

                                        <div onClick={() => handleClick("All")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" >All</div>
                                        <div onClick={() => handleClick("Graded")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" >Graded</div>
                                        <div onClick={() => handleClick("Not Graded")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" >Not Graded</div>

                                    </div>

                                </div> */}
                                <div className="relative inline-block text-left">
                                    <div>
                                        <button onClick={handleNav} type="button" className="bg-[#727CF4] inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-50 text-gray focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                            Filters
                                            <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
                                            </svg>
                                        </button>
                                    </div>

                                    <div onClick={handleNav} className={!showMenu ? "absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" : "hidden"} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                        <div className="py-1" role="none">
                                            <div onClick={() => handleClick("All")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" >All</div>
                                            <div onClick={() => handleClick("Graded")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" >Graded</div>
                                            <div onClick={() => handleClick("Not Graded")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" >Not Graded</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" justify-between items-center w-full md:flex md:w-auto md:order-1" id="navbar-search">
                            <div className=" mt-3 flex md:hidden">
                                <input type="text" id="search-navbar" className="block p-2 pl-3 w-full text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 sm:text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="08/10/2022" />
                                <div className="flex inset-y-0 left-0 items-center px-2 cursor-pointer bg-[#727CF4] rounded-r-lg">
                                    <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                </div>
                                <div className="flex inset-y-0 left-0 items-center cursor-pointer bg-[#727CF4] px-2 rounded-lg ml-2 shadow-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                                        <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                    </svg>

                                </div>
                                <div className="ml-1" >
                                    <div>
                                        <button onClick={handleNav} className="text-white bg-[#727CF4] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" >

                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
                                        </button>
                                    </div>

                                    <div onClick={handleNav} className={!showMenu ? 'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none' : "hidden"} >

                                        <div onClick={() => handleClick("All")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" >All</div>
                                        <div onClick={() => handleClick("Graded")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" >Graded</div>
                                        <div onClick={() => handleClick("Not Graded")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" >Not Graded</div>

                                    </div>

                                </div>
                                {/* <div className='bg-black'> */}

                                {/* </div> */}
                            </div>
                            {/* <button onClick={handleNav}   className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button"></button> */}

                        </div>
                    </div>
                </div>
                {/* <Dashboard totalImages={ImageData.length} /> */}
                <div className='bg-[#FAFBFE] w-full h-screen'>
                    <div className='grid md:grid-cols-12 gap-4 sm:grid-cols-12  px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 max-w-[1240px] mx-auto'>
                        {/* <div className="   rounded-lg shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"> */}

                        {currentImages.map((item, index) => {
                            return (

                                <div key={index} className="col-span-6 md:col-span-3 justify-self-center max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">

                                    <div>
                                        <img className="rounded-t-lg w-full h-[200px]" src={item.realImageUrl} alt="" />
                                    </div>
                                    <div className="p-5">
                                        <p className="mb-3 font-normal text-[#878787]">{item.fileName}</p>

                                        <button onClick={() => navigate(`/grade/${item._id}`)}
                                            className="cursor-pointer inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-[#3AB0CF] rounded-md hover:bg-[#3a9bcf] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            {item.gradings.filter(item => item.userId === user.uid).length == 1 ? "Edit Grading" : "Start Grading"}
                                            {/* {item.gradings.map((item)=>{return console.log(item)})} */}



                                        </button>

                                    </div>
                                </div>
                            )
                        })}


                        {/* </div> */}
                    </div>
                    <div className='max-w-[1240px] mx-auto'>
                        <Pagination
                            imagesPerPage={imagesPerPage}
                            currentPage={currentPage}
                            totalImages={ImageData.length}
                            paginate={paginate}
                            indexOfFirstImage={indexOfFirstImage}
                            getPrevious={getPrevious}
                            getNext={getNext}
                            imageCount={imageCount}
                        />
                    </div>

                    {/* <div className="flex justify-end mt-5">

                        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            {activePage !== 1 && <div onClick={() => setActivePage(activePage - 1)} className="hover:text-white hover:bg-[#727CF4] cursor-pointer relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 focus:z-20">
                                <span className="sr-only">Previous</span>

                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>
                            }

                            <ul>
                                {totalPagesCalculator(totalImages, LIMIT).map(page => (
                                    <li key={page} onClick={() => setActivePage(page)} className={`${activePage === page ? 'bg-[#727CF4] text-white' : ''} hover:text-white hover:bg-[#727CF4] cursor-pointer relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500  focus:z-20`}>{page}</li>
                                ))}
                            </ul>


                            {activePage !== totalPagesCalculator(totalImages, LIMIT).length && <div onClick={() => setActivePage(activePage + 1)} className="hover:text-white hover:bg-[#727CF4] cursor-pointer relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 focus:z-20">
                                <span className="sr-only">Next</span>

                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>
                            }
                        </nav>

                    </div> */}



                </div>


            </Header >

        </div >
    )
}

export default HomePage