import { makeStyles } from '@material-ui/core';
import React from 'react'
import 'iconify-icon';
import { useNavigate } from 'react-router';



const useStyles = makeStyles({
// main:{
//     backgroundColor: red
// }
});

function Cards(AdminData) {
    
    const navigate = useNavigate();
    const classes = useStyles();
    return (
        <div className='w-full '>
            <div id="main"  className='grid grid-cols-2 gap-5 '>

                <div onClick={()=>navigate('/admin/users')}  className="cursor-pointer hover:scale-105 duration-300 flex flex-col justify-center p-2 md:p-6 max-w-sm  bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                    <div className='flex justify-between mt-2'>
                        <h5 className=" -mt-2  tracking-tight text-[#BCC2C4] dark:text-white">User</h5>
                        <div className=' top-0 right-0 py-1 px-2 text-lg font-medium text-[#6E78F5] bg-[#DCDEFC] rounded border border-blue-700  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
                           
                            <iconify-icon icon="icomoon-free:users" width="20"></iconify-icon>
                        </div>
                    </div>
                    <p className="mb-3 font-bold text-2xl text-[#656E76]">{AdminData.AdminData.users}</p>
                    <div className='mt-5 text-[#FCAA8F] flex'>
                        <div className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mt-1 -ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                            </svg>
                        </div>
                        <div className='text-lg'>0</div>
                    </div>
                    <p className='text-[#BCC2C4] text-sm md:text-lg'>New users add today </p>

                </div>
                <div onClick={()=>navigate('/admin/images')} className="cursor-pointer hover:scale-105 duration-300 flex flex-col justify-center p-2 md:p-6 max-w-sm  bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                    <div className='flex justify-between mt-2'>
                        <h5 className="-mt-2  text-[#BCC2C4] tracking-tight  dark:text-white">Images</h5>
                        <div className=' top-0 right-0 py-1 px-2 text-lg font-medium text-[#6E78F5] bg-[#DCDEFC] rounded border border-blue-700  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
                            
                            <iconify-icon icon="ant-design:file-image-outlined"></iconify-icon>
                        </div>
                    </div>
                    <p className="mb-3 font-bold text-2xl text-[#656E76]">{AdminData.AdminData.images}</p>
                    <div className='mt-5 text-[#FCAA8F] flex'>
                        <div className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mt-1 -ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                            </svg>
                        </div>
                        <div className='text-lg'>0</div>
                    </div>
                    <p className='text-[#BCC2C4] text-sm md:text-lg'>New images add today </p>

                </div>
                <div onClick={()=>navigate('/admin/graded_images')} className="cursor-pointer hover:scale-105 duration-300 flex flex-col justify-center p-2 md:p-6 max-w-sm  bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                    <div className='flex justify-between mt-2'>
                        <h5 className="-mt-2 text-[#BCC2C4] tracking-tight  dark:text-white">Graded Images</h5>
                        <div className=' top-0 right-0 py-1 px-2 text-lg font-medium text-[#6E78F5] bg-[#DCDEFC] rounded border border-blue-700  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
                           
                            <iconify-icon icon="bxs:image-alt"></iconify-icon>
                        </div>
                    </div>
                    <p className="mb-3 font-bold text-2xl text-[#656E76]">{AdminData.AdminData.gradings}</p>
                    <div className='mt-5 text-[#FCAA8F] flex'>
                        <div className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mt-1 -ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                            </svg>
                        </div>
                        <div className='text-lg'>0</div>
                    </div>
                    <p className='text-[#BCC2C4] text-sm md:text-lg'>New images graded today </p>

                </div>
                <div className="cursor-pointer hover:scale-105 duration-300 flex flex-col justify-center p-2 md:p-6 max-w-sm  bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                    <div className='flex justify-between'>
                        <h5 className="-mt-2  text-[#BCC2C4] tracking-tight dark:text-white">ML Processed</h5>
                        <div className=' top-0 right-0 py-1 px-2 text-lg font-medium text-[#FFFFFF] bg-[#727CF4] rounded border border-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
                          
                            <iconify-icon icon="ci:refresh-02"></iconify-icon>
                        </div>
                    </div>
                    <p className="mb-3 font-bold text-2xl text-[#656E76]">2905</p>
                    <div className='mt-5 text-[#FCAA8F] flex'>
                        <div className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mt-1 -ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                            </svg>
                        </div>
                        <div className='text-lg'>0</div>
                    </div>
                    <p className='text-[#BCC2C4] text-sm md:text-lg'>ML processed Today </p>

                </div>
            </div>

            
        </div>
    )
}

export default Cards