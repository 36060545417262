import React from 'react'

function index() {
    return (

        <div className="p-4 w-full max-w-[1240px] sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex justify-between items-center">
                <h5 className="text-[#7A8289] text-lg font-bold leading-none dark:text-white uppercase">Coflicts between Graders</h5>
                <div className="text-sm font-medium text-[#616A73] hover:underline dark:text-blue-500">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path></svg>
                </div>
            </div>

            <div className="overflow-x-auto relative mt-5">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className=" text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                Id
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Name
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Completions
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Creation Date
                            </th>
                            <th scope="col" className="py-3 px-6">
                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[#A5ABB0]">
                            <td className="py-4 px-6">
                                1024
                            </td>
                            <td className="py-4 px-6">
                                123.jpg
                            </td>
                            <td className="py-4 px-6">
                                5
                            </td>
                            <td className="py-4 px-6">
                                20/08/22
                            </td>
                            <td className="py-4 px-6">
                            <iconify-icon style={{ color: '#7D87F5' }} icon="bi:arrow-right-circle" width='16'></iconify-icon>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[#A5ABB0]">
                            <td className="py-4 px-6">
                                1024
                            </td>
                            <td className="py-4 px-6">
                                123.jpg
                            </td>
                            <td className="py-4 px-6">
                                5
                            </td>
                            <td className="py-4 px-6">
                                20/08/22
                            </td>
                            <td className="py-4 px-6">
                            <iconify-icon style={{ color: '#7D87F5' }} icon="bi:arrow-right-circle" width='16'></iconify-icon>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-[#A5ABB0]">
                            <td className="py-4 px-6">
                                1024
                            </td>
                            <td className="py-4 px-6">
                                123.jpg
                            </td>
                            <td className="py-4 px-6">
                                5
                            </td>
                            <td className="py-4 px-6">
                                20/08/22
                            </td>
                            <td className="py-4 px-6">
                            <iconify-icon style={{ color: '#7D87F5' }} icon="bi:arrow-right-circle" width='16'></iconify-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>



    )
}

export default index