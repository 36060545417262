import React, { forwardRef } from 'react'
import Navbar from '../Navbar/Navbar'
import { Helmet } from "react-helmet";

const index = ({ children},props) => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.current}</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <Navbar/>
            <main>
                {children}
            </main>
        </>
    )
}

export default forwardRef(index)
