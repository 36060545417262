import React from 'react'
import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import GradeImageComponent from '../../Components/Grade/GradeImageComponent'
import GradeNav from '../../Components/Grade/GradeNav'
import Header from '../../Components/Header/Header'
import { useState } from 'react'
import GradeForm from '../../Components/Grade/GradeForm'
import Split from 'react-split'
import "./style.css";

import {
    useParams
} from "react-router-dom";

import {
    useDispatch
} from 'react-redux'
import { data as GradeFormData } from '../../Components/Config/Config'
import { Grid } from '@material-ui/core'

function Grade() {
    var data = GradeFormData.double
    const match = useParams()
    const dispatch = useDispatch();
    var imageData = useSelector(state => state.imageData)
    const user = useSelector(state => state.user)
    //var ImageData = useSelector(state => state.imageData)
    const myRef = useRef('Grade')
    //console.log(user)
    //const id = user.uid
    const [fetchingData, setFetchingData] = useState(false)



    const getImageById = async () => {
        setFetchingData(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/getImageById`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: match.id,

            }),
        })

        setFetchingData(false)
        const response_data = await response.json()
        //console.log(response_data)


        // If no gradings found
        let grading_data = {}
        var graded = false

        response_data.imgData.gradings?.map((item) => {
            //console.log(item)
            if (item.userId === user.uid) {
                grading_data = item.data
                graded = true
                // dispatch({ type: "Fetch_Grading_Image_Data", gradingImageData: item.data })
            }
        })

        var data = GradeFormData[response_data.imgData.type]
        if (Object.values(grading_data).length == 0) {
            data.map(item => {
                grading_data[item.id] = ""
            })
        }


        // If there is already gradings found for user, use those grading


        dispatch({ type: "Fetch_Image_Data", data: response_data.imgData, gradingData: grading_data, graded: graded })

    }
    // const checkImageIsGraded = async () => {
    //   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/getImageById`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       _id: match.id,

    //     }),
    //   })
    //   const response_data = await response.json()

    //   response_data.imgData.gradings.map((item)=>{
    //     console.log(item)
    //     if(item.userId===user.uid){

    //       dispatch({ type: "Fetch_Grading_Image_Data", gradingImageData: item.data })
    //     }

    //   })
    // }



    useEffect(() => {
        getImageById()
        //checkImageIsGraded()
    }, [match])

    if (fetchingData) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }

    return (
        <div className='bg-[#FAFBFE] w-full h-screen'>
            <Header ref={myRef}>
                <GradeNav />
                <div style={{ height: 'calc(100vh - 111px)' }}>

                    {imageData.data.fakeImageUrl ?
                        <Grid container spacing={0} className="wrap">
                            <Grid item xs={12} md={8} className="comp1">
                                <Split gutterSize={5} className="comp2 intterWrap" sizes={[50, 50]} direction="horizontal">
                                    <GradeImageComponent type='fake' />
                                    <GradeImageComponent type='real' />
                                </Split>
                            </Grid>
                            <Grid item xs={12} md={4} className="comp3">
                                <GradeForm />
                            </Grid>
                        </Grid>
                        :
                        <Split
                            className="wrap"
                            sizes={[75, 25]}
                            minSize={100}
                            expandToMin={false}
                            gutterSize={5}
                            gutterAlign="center"
                            snapOffset={30}
                            //dragInterval={1}
                            direction="horizontal"
                            cursor="col-resize"
                        >
                            <GradeImageComponent type='real' />
                            <GradeForm />
                        </Split>

                    }
                </div>



            </Header>
        </div>
    )
}

export default Grade