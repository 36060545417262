import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router';
import Dashboard from '../../../Components/Dashboard/Dashboard'
import Header from '../../../Components/Header/Header'
import Loading from '../../../Components/Loading/Loading';

function GradingByUser() {
    const myRef = useRef('Grading By User')
    //const { state } = useLocation();
    const [UserGradings,setUserGradings] = useState({})
    const [loading,setLoading] = useState(false)

    const getUserGradings = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/gradings/user`, {

            headers: {
                'x-access-token': localStorage.getItem('token'),
            },

        })
        const response_data = await response.json()
        //console.log(response_data)
        setUserGradings(response_data)
        setLoading(false)
    }

    useEffect(() => {
        getUserGradings()
    }, [])
    if (loading) {
        return (<Loading />)
      }

    return (
        <div className='bg-[#FAFBFE] w-full h-screen'>
            <Header ref={myRef}>
                <Dashboard />
                
                <div className='bg-[#FAFBFE] w-full h-screen'>
                    <div className='border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 max-w-[1240px] mx-auto'>
                        <div className=" max-w-[1240px] mx-auto bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                            <div className="flex justify-between items-center mb-4 px-4 py-3 md:px-0 md:py-0">
                                <h5 className="text-[#7A8289] text-lg font-bold leading-none  dark:text-white">User Gradings</h5>
                                {/* <div className="text-sm font-medium text-[#616A73] hover:underline dark:text-blue-500">
                  <span onClick={() => navigate('/admin/graded_images/by_user')} className=' text-[#727CF4] cursor-pointer text-lg '>By User</span>
                </div> */}
                            </div>
                            
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className=" text-[#7A8289] bg-gray-50 dark:bg-gray-700 ">
                                    <tr>
                                        <th scope="col" className="py-3 px-3">

                                        </th>
                                        <th scope="col" className="py-3 px-3">
                                            User
                                        </th>
                                        <th scope="col" className="py-3 px-3">
                                            Grading Images
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {Object.entries(UserGradings).map((item, index) => {
                                        return (
                                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" >
                                                <td className="py-4 px-3 ">
                                                    {index + 1}
                                                </td>
                                                <td className="py-4 px-3">
                                                    {item[0]}
                                                </td>
                                                <td className="py-4 px-3">
                                                    {item[1]}
                                                </td>

                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Header>
        </div>
    )
}

export default GradingByUser