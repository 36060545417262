import React, { useRef } from 'react'
import Dashboard from '../../Components/Dashboard/Dashboard'
import Header from '../../Components/Header/Header'

function User() {
  const myRef = useRef('User Dashboard')
  return (
    <div className='bg-[#FAFBFE] w-full h-screen'>
      <Header ref={myRef}>
       <Dashboard/>
      </Header>
    </div>
  )
}

export default User