

import React, { useRef, useState } from 'react'
import Cards from '../../Components/Cards/Cards';
import Table from '../../Components/Table/index'
import Graphs from '../../Components/Graphs/BarGraph';
import DonutGraph from '../../Components/Graphs/DonutGraph';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Components/Dashboard/Dashboard';
import { useEffect } from 'react';
import Loading from '../../Components/Loading/Loading';


function AdminDashboard() {

  const myRef = useRef('Home')
  const [AdminData, setAdminData] = useState({})
  const [loading,setLoading] = useState(false)

  const adminData = async () => {
    setLoading(true)
    let data_obj;
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/adminData`, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
      },
    })
    const response_data = await response.json()
    //console.log(response_data)

    data_obj = response_data
    //console.log(data_obj)
    setAdminData(data_obj)
    setLoading(false)

  }

  //console.log(AdminData)


  useEffect(() => {
    adminData()
  }, [])

  if (loading) {
    return (<Loading />)
  }

  return (

    <div className='bg-[#FAFBFE] w-full h-screen'>
      <Header ref={myRef}>
        <Dashboard />
        <div className=" border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 max-w-[1240px] mx-auto">
          <div className=' grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 md:gap-8 '>
            {/* Card Componet */}
            <div className=''>
              <Cards AdminData={AdminData}/>

            </div>
            {/* Card Componet */}

            {/* Graph Componet */}
            <div className='bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700'>
              <Graphs />
            </div>
            {/* Graph Componet */}
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 md:gap-8'>
            <div className=' bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700'><DonutGraph /></div>
            <div className='bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700'><Table /></div>
          </div>

        </div>
      </Header>
    </div>


  )
}

export default AdminDashboard