import React from 'react'
import Chart from 'react-apexcharts'



function DonutGraph() {
  const state = {
    options: {
      legend: { show: false },
      dataLabels: { enabled: false },
      //tooltip: { enabled: false },
      fill: { colors: ["#727CF5", "#0ACF97"] },
    },

    series: [3, 90,],
    labels: ['A', 'B']
  };
  return (
    <>
      <div className="p-4 w-full max-w-[1240px] sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <div className="">
          <h5 className="text-[#7A8289] text-lg font-bold leading-none dark:text-white uppercase">ML STATUS</h5>

        </div>
        <div className='mt-5 overflow-x-auto relative flex flex-col justify-center items-center'>
          <div>
            <Chart
              options={state.options} series={state.series} type="donut" width="380"
            />
          </div>
          <div className='flex justify-around items-center w-full'>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#0ACF97]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
              </svg>
              <p className='text-[#646A74]'>90</p>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#727CF5]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
              </svg>
              <p className='text-[#646A74]'>3</p>
            </div>
          </div>

        </div>
      </div>
    </>




    // <div className='flex justify-around items-center mx-auto mb-5'>
    //   <div>
    //     <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-[#0ACF97]" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    //       <path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
    //     </svg>
    //     <p className='text-[#646A74]'>90</p>
    //   </div>
    //   <div>
    //     <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-[#727CF5]" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    //       <path stroke-linecap="round" stroke-linejoin="round" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
    //     </svg>
    //     <p className='text-[#646A74]'>3</p>
    //   </div>
    // </div> 

  )
}

export default DonutGraph