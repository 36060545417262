import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from '@material-ui/core';

// const auth = getAuth()

function Register() {

    const [formSubmitting, setFormSubmitting] = useState(false)
    const [message, setMessage] = useState("")
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        name: "",
        email: "",
        password: "",
    })





    const changeHandler = e => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }

    const SignUp = async(e) => {

        setFormSubmitting(true)
        e.preventDefault()

        if (userData.name === "") {

            setFormSubmitting(false)
            return setMessage("Enter your name")


        } else if (userData.name.includes("@") || userData.name.includes(".")) {
            setFormSubmitting(false)
            return setMessage("Enter a valid name")
        }
        else if (userData.email === "") {

            setFormSubmitting(false)
            return setMessage("Email is empty")

        } else if (regex.test(userData.email) === false) {

            setFormSubmitting(false)
            return setMessage("Please enter a valid email address")

        }
        else if (userData.password === "") {

            setFormSubmitting(false)
            return setMessage("Password is empty")

        } else if (userData.password.length <= 7) {

            setFormSubmitting(false)
            return setMessage("Password is too short")
        }



       
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: userData.name,
                    email: userData.email,
                    password: userData.password,
                    role: 'admin',
                    approved: false,
                }),
            })
            const data = await response.json()
            console.log(data)
            if (data.status === 'ok'){
                //dispatch({ type: 'Fetch_User_Success', email: data.user.email, uid: data.user._id, name: data.user.name, role: data.user.role }) 
                navigate('/login')
            }else{
                setMessage(data.error)
                setFormSubmitting(false)
            }

     }




    return (
        <section className="h-screen w-full gradient-form bg-gray-200">

            <div className="flex justify-center items-center h-full text-gray-800">
                <div className="max-w-[1240px]">
                    <div className="block bg-white shadow-lg rounded-lg">
                        <div className="lg:flex lg:flex-wrap g-0">
                            <div className="w-full px-4 md:px-0 flex flex-col justify-center items-center">
                                <div className="md:p-12 md:mx-6">
                                    <div className="text-center">
                                        <img
                                            className="mx-auto w-60"
                                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                                            alt="logo"
                                        />
                                        <h4 className="text-xl font-semibold mt-1 mb-12 pb-1">Image Grading Portal</h4>
                                    </div>
                                    <form>
                                        <p className="mb-4">Please register to your account</p>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

                                                placeholder="Enter Your Name"
                                                name='name'
                                                onChange={(e) => changeHandler(e)}

                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="email"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

                                                placeholder="Enter Your Email"
                                                name='email'

                                                onChange={(e) => changeHandler(e)}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="password"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

                                                placeholder="Password"
                                                name='password'

                                                onChange={(e) => changeHandler(e)}
                                            />
                                        </div>
                                        <div className="text-center pt-1 mb-12 pb-1">
                                            <button
                                                onClick={(e) => SignUp(e)}
                                                className="inline-block bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 px-6 py-4 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3" >
                                                {formSubmitting ? <CircularProgress color='inherit' size={25} /> : "SignUp"}

                                            </button>
                                            <p className='text-sm text-red-600'>{message}</p>

                                        </div>
                                        <div className="flex items-center justify-between pb-6">
                                            <p className="mb-0 mr-2">Already have an account?</p>
                                            <Link to='/login'>
                                                <button

                                                    className="inline-block px-6 py-2 border-2 border-red-600 text-red-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                                >
                                                    Sign In
                                                </button>
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Register