import { Navigate } from "react-router";
import { useSelector } from "react-redux";

const UnAuthRoute = ({ auth, children }) => {
  var user = useSelector(state => state.user)
  
  if (!auth) {
    return children
  } else {
    if (user.role === "admin") {
      return <Navigate to="/admin" />
    } 
    else
      if (user.role === "grader") {
        return <Navigate to="/" />
      } else
        if (user.role === "user") {
          return <Navigate to="/user" />
        }
  }
  // return auth ? <Navigate to="/" /> : children
};
export { UnAuthRoute }

