
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { CircularProgress } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




// const auth = getAuth()

function Login() {

    const dispatch = useDispatch();

    const [user, setUser] = useState({
        email: "",
        password: "",
    })
    const [formSubmitting, setFormSubmitting] = useState(false)
    const [message, setMessage] = useState("")
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;



    const changeHandler = e => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const navigate = useNavigate();




    const SignIn = async (e) => {

        e.preventDefault()
        setFormSubmitting(true)
        setMessage(" ")





        if (user.email === "") {

            setFormSubmitting(false)
            return setMessage("Email is empty")

        } else if (regex.test(user.email) === false) {

            setFormSubmitting(false)
            return setMessage("Please enter a valid email address")

        }
        else if (user.password === "") {

            setFormSubmitting(false)
            return setMessage("Password is empty")

        } else if (user.password.length <= 7) {

            setFormSubmitting(false)
            return setMessage("Your Password is incorrect")
        }


        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: user.email,
                password: user.password,
            }),
        })

        const response_data = await response.json()

        

        if (response_data.token) {
            localStorage.setItem('token', response_data.token)



            
            if (response_data.user.approved === 'true') {

                dispatch({ type: 'Fetch_User_Success', email: response_data.user.email, uid: response_data.user._id, name: response_data.user.name, role: response_data.user.role, approved: response_data.user.approved })
                setFormSubmitting(false)

               
                
            } else {
                toast.warn('User in not approved from admin!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setFormSubmitting(false)
            }

        } else {
            setFormSubmitting(false)
            toast.error('UnAuthorized Token!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }





    return (
        <section className="h-screen w-full gradient-form bg-gray-200">

            <div className="flex justify-center items-center h-full text-gray-800">
                <div className="max-w-[1240px]">
                    <div className="block bg-white shadow-lg rounded-lg">
                        <div className="lg:flex lg:flex-wrap g-0">
                            <div className="w-full px-4 md:px-0 flex flex-col justify-center items-center">
                                <div className="md:p-12 md:mx-6">
                                    <div className="text-center">
                                        <img
                                            className="mx-auto w-60"
                                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                                            alt="logo"
                                        />
                                        <h4 className="text-xl font-semibold mt-1 mb-12 pb-1">Image Grading Portal</h4>
                                    </div>
                                    <form>
                                        <p className="mb-4">Please login to your account</p>

                                        <div className="mb-4">
                                            <input
                                                type="email"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

                                                placeholder="Enter Your Email"

                                                name='email'

                                                onChange={(e) => changeHandler(e)}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="password"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

                                                placeholder="Password"

                                                name='password'

                                                onChange={(e) => changeHandler(e)}
                                            />
                                        </div>
                                        <div className="text-center pt-1 mb-12 pb-1">
                                            <button
                                                onClick={(e) => SignIn(e)}
                                                className="inline-block bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 px-6 py-4 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3" >
                                                {formSubmitting ? <CircularProgress color='inherit' size={25} /> : "LogIn"}

                                            </button>
                                            {/* <button
                                                // onClick={(e) => SignIn(e)}
                                                className="inline-block bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 px-6 py-4 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3" >
                                                logIn With Google
                                            </button> */}
                                            <p className='text-sm text-red-600'>{message}</p>
                                        </div>

                                        <div className="flex items-center justify-between pb-6">
                                            <p className="mb-0 mr-2">Don't have an account?</p>
                                            <Link to='/register'>
                                                <button

                                                    className="inline-block px-6 py-2 border-2 border-red-600 text-red-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                                >
                                                    Sign Up
                                                </button>
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </section>
    )
}

export default Login