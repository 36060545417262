import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, Select } from '@material-ui/core'
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//-------------pagination-----------
const LIMIT = 10;

const totalPagesCalculator = (total, limit) => {
    
    const pages = [];
    
    for (let x = 1; x <= Math.ceil(total/limit); x++) {
        pages.push(x);
    }

    return pages;
}
//-------------pagination-----------


function UsersTable() {
    //-------------pagination-----------


    const [activePage, setActivePage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const indexOfLastUser = activePage * LIMIT;
    const indexOfFirstUser = indexOfLastUser - LIMIT;
    //console.log(indexOfFirstUser,indexOfLastUser)

    //-------------pagination-----------

    const [checked, setIsChecked] = useState(false)
    const [usersData, setUsersData] = useState([])

    var userData = useSelector(state => state.user)
    const dispatch = useDispatch();

    const date = moment(new Date()).format("DD/MM/YYYY")




    const updateRole = async (id, e, index) => {

        let user_role = e.target.value

        if (userData.role === "admin") {

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/updateRole`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _id: id,
                    role: user_role,
                }),
            })


            const response_data = await response.json()
            if (response_data) {
                toast.success('Role Updated Successfully!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            //console.log(response_data)

            //console.log(response_data.user.role)
            let users_temp = [...usersData]
            users_temp[index].role = response_data.user.role
            const myarr = []
            users_temp.forEach((doc) => {
                myarr.push(doc)

            })
            setUsersData(myarr)
        } else {
            toast.warn('Only Admin can update the user role!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }

    const RemoveData = async (user_id, name) => {

        if (userData.role === "admin") {

            const filtered = usersData.filter((data) => {
                return data._id !== user_id
            });
            toast.success(`${name} is removed`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setUsersData(filtered)

            await fetch(`${process.env.REACT_APP_BASE_URL}/api/deleteUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _id: user_id,

                }),
            })
        } else {
            toast.warn('Only Admin can remove the user!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }



    const getAllUsers = async () => {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/allUsers`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('token'),
                    page: activePage,
                    size: LIMIT
                },

            })
        const data = await response.json()
        //console.log(data)
        setTotalUsers(data.total);
        const myarr = []
        data.users.forEach((doc) => {

            myarr.push(doc)

        })
        setUsersData(myarr)
    }

    useEffect(() => {
        getAllUsers()
    }, [activePage])

    //console.log(usersData)

    const approved = async (id, e, index) => {

        //console.log(index)
        // let user_role = e.target.value
        // users_temp[index].role = response_data.user.role

        if (userData.role === "admin") {

            setIsChecked(e.target.checked)

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/approved`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _id: id,
                    approved: e.target.checked,
                }),
            })

            const response_data = await response.json()



            if (response_data.user.approved === 'true') {

                toast.success('User Approved Successfully!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


            } else {
                toast.success('User DisApproved Successfully!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            //console.log(response_data.user.approved)
            let users_temp = [...usersData]
            //console.log(users_temp[index])
            users_temp[index].approved = response_data.user.approved
            const myarr = []
            users_temp.forEach((doc) => {

                myarr.push(doc)

            })
            setUsersData(myarr)
        } else {
            toast.warn('Only Admin can approved the user!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }




    return (
        <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className=" text-[#7A8289] bg-gray-50 dark:bg-gray-700 ">
                    <tr>
                        <th scope="col" className="py-3 px-3">

                        </th>
                        <th scope="col" className="py-3 px-3">
                            Name
                        </th>
                        <th scope="col" className="py-3 px-3">
                            Email
                        </th>
                        <th scope="col" className="py-3 px-3">
                            Join Date
                        </th>
                        <th scope="col" className="py-3 px-3">
                            Admin?
                        </th>
                        <th scope="col" className="py-3 px-3">
                            Approved?
                        </th>
                        <th scope="col" className="py-3 px-3">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {usersData.map((item, index) => {
                        return (

                            < tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" >
                                <td className="py-4 px-3 ">
                                    {indexOfFirstUser + index + 1}
                                </td>
                                <td className="py-4 px-3">
                                    {item.name}
                                </td>
                                <td className="py-4 px-3">
                                    {item.email}
                                </td>
                                <td className="py-4 px-3">
                                    {moment(item.date).format("DD/MM/YYYY")}

                                </td>
                                <td className="">

                                    <select value={item.role} onChange={(e) => updateRole(item._id, e, index)} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option defaultValue>Role</option>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                        <option value="grader">Grader</option>
                                    </select>

                                    {/* <FormControl style={{ width: "65%",border: '1px solid gray'}}>
                                        <Select                                        
                                            displayEmpty
                                            value={item.role}
                                            onChange={(e) => updateRole(item._id, e, index)} 
                                            style={{paddingLeft: '5px'}}                                           
                                        >
                                            <MenuItem  value={'user'}>User</MenuItem>
                                            <MenuItem value={'admin'}>Admin</MenuItem>
                                        </Select>
                                    </FormControl> */}
                                </td>
                                <td className="py-4 px-3">

                                    <label className="inline-flex relative items-center cursor-pointer">


                                        <input type="checkbox" checked={item.approved === "true" ? true : false} onChange={(e) => approved(item._id, e, index)} className="sr-only peer" />



                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    </label>
                                </td>
                                <td className="py-4 px-3 ">

                                    <iconify-icon onClick={() => RemoveData(item._id, item.name)} style={{ color: '#FA5C7C', cursor: 'pointer' }} icon="ant-design:delete-filled" height='20'></iconify-icon>
                                </td>
                            </tr>
                        )

                    })}
                </tbody>

            </table>
            
            <ToastContainer />
            <div className="flex justify-end mt-5">

                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    {activePage !== 1 && <div onClick={() => setActivePage(activePage - 1)} className="hover:text-white hover:bg-[#727CF4] cursor-pointer relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 focus:z-20">
                        <span className="sr-only">Previous</span>

                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                        </svg>
                    </div>
                    }

                    <ul>
                        {totalPagesCalculator(totalUsers, LIMIT).map(page => (
                            <li key={page} onClick={() => setActivePage(page)} className={`${activePage === page ? 'bg-[#727CF4] text-white' : ''} hover:text-white hover:bg-[#727CF4] cursor-pointer relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500  focus:z-20`}>{page}</li>
                        ))}
                    </ul>


                    {activePage !== totalPagesCalculator(totalUsers, LIMIT).length && <div onClick={() => setActivePage(activePage + 1)} className="hover:text-white hover:bg-[#727CF4] cursor-pointer relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 focus:z-20">
                        <span className="sr-only">Next</span>

                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                        </svg>
                    </div>
                    }
                </nav>

            </div>

        </>
    )
}

export default UsersTable