// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAVuWQUcR2-JiSyaIujdUDvdcEFfgKVwd0",
  authDomain: "comppec-2022.firebaseapp.com",
  projectId: "comppec-2022",
  storageBucket: "comppec-2022.appspot.com",
  messagingSenderId: "905804643599",
  appId: "1:905804643599:web:0223492f3f82725589d27a",
  measurementId: "G-ZJHPJJHPYN"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyD9SaRqNoD9UHtRmflJaFJz2SIYrsOst7k",
//   authDomain: "image-grader.firebaseapp.com",
//   projectId: "image-grader",
//   storageBucket: "image-grader.appspot.com",
//   messagingSenderId: "54136183632",
//   appId: "1:54136183632:web:bfd102e0a08f6e62f0ea55"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app,process.env.REACT_APP_BUCKET_URL);

export default storage;