import React, { useRef } from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router';
import Dashboard from '../../../Components/Dashboard/Dashboard';
import Header from '../../../Components/Header/Header'
import ImagesTable from '../../../Components/ImagesTable/ImagesTable'

function Images() {
    const navigate = useNavigate();
    const myRef = useRef('Images')
    
    return (
        <div className='bg-[#FAFBFE] w-full h-screen'>
            <Header ref={myRef}>
                <Dashboard />
                <div className='bg-[#FAFBFE] w-full h-screen'>
                    <div className='border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 max-w-[1240px] mx-auto'>
                        <div className=" max-w-[1240px] mx-auto bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                            <div className="flex justify-between items-center mb-4 px-4 py-3 md:px-0 md:py-0">
                                <h5 className="text-[#7A8289] text-lg font-bold leading-none  dark:text-white">Images</h5>
                                <div className="text-sm font-medium text-[#616A73] hover:underline dark:text-blue-500">
                                    <button onClick={() => navigate('/admin/images/upload')} className='border border-[#696cff] text-[#727CF4] hover:text-white hover:bg-[#727CF4] rounded-md text-sm pt-1 pb-1 pl-2 pr-2'>Upload</button>
                                </div>
                            </div>

                            <div className="overflow-x-auto relative">
                                <ImagesTable />
                            </div>
                        </div>
                    </div>
                </div>
            </Header>
        </div>
    )
}

export default Images