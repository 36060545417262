const initState = {
    email: null,
    uid: null,
    name: null,
    role: null,
    approved: false,
    isLoading: true,
    errorMessage: " "
}
const userReducer = (state = initState, action) => {
    switch (action.type) {
        case "Fetch_User_Success":
            return {
                ...state,
                email: action.email,
                uid: action.uid,
                name: action.name,
                approved: action.approved,
                isLoading: false,
                role: action.role,
            }
           
        case "Fetch_User_Error":
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload,
            }
        case "USER_CLEAR":
            return {
                ...state,
                email: null,
                uid: null,
                name: null,
                role: null,
                
            };
        default:
            return state
    }
}
export default userReducer