import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { data } from '../Config/Config'

function GradeImageComponent(props) {
  const match = useParams()
  const dispatch = useDispatch();
  var imageData = useSelector(state => state.imageData)
  const user = useSelector(state => state.user)
  // {`bg-black ${window.innerWidth <= 768 ? 'mobile-height' : 'desktop-height'}`}
  // ${window.innerWidth <= 768 ? 'mobile-height' : 'desktop-height'}

  return (
    <div className="bg-black desktop-height1">
      <div className='bg-[#462d2d] h-7' style={{ padding: "2px", paddingLeft: "10px", fontSize: 15 }}>
        {props.type === 'real' ? ' B' : 'A'}
      </div>
      <div className='flex justify-center items-center desktop-height2 p-2' style={{
        flexDirection: 'column',
      }}>
        <img src={props.type === 'real' ? imageData.data.realImageUrl : imageData.data.fakeImageUrl}
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
            width: 'auto',
          }}
        />
      </div>
    </div>


  )
}

export default GradeImageComponent