import React, { useEffect } from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { data as GradeFormData } from '../Config/Config'


const options = {
  double: [
    [{ "value": "1", "label": "Strongly Disagree" }, { "value": "2", "label": "Disagree" }, { "value": "3", "label": "Neither agree nor disagree" }, { "value": "4", "label": "Agree" }, { "value": "5", "label": "Strongly agree" }, { "value": "6", "label": "fungi resembling structures are not visible in either image" }],
    [{ "value": "1", "label": "Strongly Disagree" }, { "value": "2", "label": "Disagree" }, { "value": "3", "label": "Neither agree nor disagree" }, { "value": "4", "label": "Agree" }, { "value": "5", "label": "Strongly agree" }],
    [{ "value": "1", "label": "Strongly Disagree" }, { "value": "2", "label": "Disagree" }, { "value": "3", "label": "Neither agree nor disagree" }, { "value": "4", "label": "Agree" }, { "value": "5", "label": "Strongly agree" }],
    [{ "value": "1", "label": "Strongly Disagree" }, { "value": "2", "label": "Disagree" }, { "value": "3", "label": "Neither agree nor disagree" }, { "value": "4", "label": "Agree" }, { "value": "5", "label": "Strongly agree" }],
    [{ "value": "1", "label": "Strongly Disagree" }, { "value": "2", "label": "Disagree" }, { "value": "3", "label": "Neither agree nor disagree" }, { "value": "4", "label": "Agree" }, { "value": "5", "label": "Strongly agree" }],
    [{ "value": "1", "label": "Strongly Disagree" }, { "value": "2", "label": "Disagree" }, { "value": "3", "label": "Neither agree nor disagree" }, { "value": "4", "label": "Agree" }, { "value": "5", "label": "Strongly agree" }],
  ],
  single: [
    [{ "value": "1", "label": "Adequate" }, { "value": "2", "label": "Inadequate" }],
    [{ "value": "1", "label": "Adequate" }, { "value": "2", "label": "Inadequate" }],
    [{ "value": "1", "label": "Adequate" }, { "value": "2", "label": "Inadequate" }],
    [{ "value": "1", "label": "Adequate" }, { "value": "2", "label": "Inadequate" }],
    [{ "value": "1", "label": "Yes" }, { "value": "2", "label": "No" }],
    [{ "value": "1", "label": "Yes" }, { "value": "2", "label": "No" }]
  ]
}






function GradeForm() {
  //const [questions, setQuestions] = useState(data)
  const [currentPage, setCurrentPage] = useState(1)
  const [questionsPerPage] = useState(1)
  const dispatch = useDispatch()
  const imageData = useSelector(state => state.imageData)

  var data = imageData.data.type ? GradeFormData[imageData.data.type] : GradeFormData.double

  const {
    gradingImageData,
    gradingErrors,

  } = imageData

  //Get Current Question
  const indexOfLastQuestion = currentPage * questionsPerPage
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage
  const currentQuestion = data.slice(indexOfFirstQuestion, indexOfLastQuestion)




  //Get Previous Page
  const getPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  };

  //Get Next Page

  const getNext = () => {
    if (currentPage < data.length) {
      setCurrentPage(currentPage + 1)
    }
  };


  const radioColors = {
    default: '#000000',
    selected: "#09F",
    error: '#FF0000',
  }




  //Change Page
  useEffect(() => {
    setCurrentPage(1)
  }, [imageData.data])

  const user = useSelector(state => state.user)


  const radio_change = (data_label, value) => {

    if (currentPage > 0 && currentPage < data.length) {

      setCurrentPage(currentPage + 1)
    }

    //console.log(data_label, value)
    dispatch({
      type: "ON_GRADE_CHANGE",
      key: data_label,
      value: value
    })
  }

  //Key Event Listner

  const handleUserKeyPress = (e) => {


    if (["1", "2", "3", "4", "5"].includes(e.key)) {
      if (currentPage > 0 && currentPage < data.length) {
        setCurrentPage(currentPage + 1)
      }
      dispatch({
        type: "ON_GRADE_CHANGE",
        key: currentQuestion[0].id,
        value: e.key
      })
    }
    if (e.key === "ArrowLeft") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1)
      }
    }
    if (e.key === "ArrowRight") {
      if (currentPage < data.length) {
        setCurrentPage(currentPage + 1)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);




  var radio_options = imageData.data.type ? options[imageData.data.type][currentPage - 1] : options.double[currentPage - 1]

  return (
    <div className='overflow-x-auto pb-4' style={{ fontFamily: 'sans-serif !important' }}>
      {currentQuestion.map((item, index) => {

        return (
          <div key={index} className='p-4'>
            <div className='bg-[#FFFFFF]' style={{ fontSize: '18px', fontWeight: '500', minHeight: '70px' }}>{item.title}</div>
            <div>

              <div className='mt-1 flex flex-col'>
                {radio_options.map((option, option_index) => {
                  return (
                    <div key={option_index} className='flex items-center' style={{ marginBottom: "3px" }}
                      onClick={() => radio_change(item.id, option.value)}>

                      <div key={option_index}
                        className="border w-[15px] h-[15px] cursor-pointer md:ml-[20px] rounded-full border-black "

                        style={{
                          borderColor: gradingImageData[item.id] === option.value ? radioColors.selected : gradingErrors[item.id] ? radioColors.error : null,
                          backgroundColor: gradingImageData[item.id] === option.value ? radioColors.selected : null,
                        }}>
                      </div>
                      <div style={{
                        fontSize: '17px', fontWeight: '450',
                        // Make text unslectable
                        WebkitTouchCallout: "none", /* iOS Safari */
                        WebkitUserSelect: "none", /* Safari */
                        KhtmlUserSelect: "none", /* Konqueror HTML */
                        MozUserSelect: "none", /* Firefox */
                        MsUserSelect: "none", /* Internet Explorer/Edge */
                        userSelect: "none", /* Non-prefixed version, currently
                                          supported by Chrome and Opera */
                      }} className='ml-2 cursor-pointer'>{option_index + 1}. {option.label}</div>
                    </div>)
                })}
              </div>
            </div>

          </div>
        )
      })}

      <div className='flex justify-space-between mr-4 mt-5'>
        {currentPage === 1 ? "" :
          <button onClick={() => getPrevious()} className="bg-[#1976D2] hover:bg-blue-700 text-white font-bold py-2 px-4 border border-[#1976D2] rounded">
            Prev
          </button>
        }

        {currentPage === data.length ? "" :
          <button onClick={() => getNext()} className="bg-[#1976D2] hover:bg-blue-700 ml-1 text-white font-bold py-2 px-4 border border-[#1976D2] rounded">
            Next
          </button>
        }
        {/* {currentPage === 8 &&
          <button onClick={() => getNext()} className="bg-[#7C9422] hover:bg-blue-700 ml-1 text-white font-bold py-2 px-4 border border-[#1976D2] rounded">
            Save
          </button>
        } */}
      </div>


    </div>
  )
}

export default GradeForm