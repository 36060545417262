import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

function Dashboard() {

    const navigate = useNavigate();
    var user = useSelector(state => state.user)

    const [showMenu, setShowMenu] = useState(true)
    const handleNav = () => {
        setShowMenu(!showMenu)
    }

    const handleDashboard = () => {
        if (user.role === "admin") {
            return navigate('/admin')
        } else if (user.role === "user") {
            return navigate('/user')
        }
    }

    return (
        <div className=' px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 max-w-[1240px] mx-auto bg-[#FAFBFE] mt-5'>

            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <div className="flex items-center">
                    <div onClick={() => handleDashboard()} className="cursor-pointer self-center text-xl text-[#657C92] font-semibold whitespace-nowrap dark:text-white">Dashboard  </div>
                </div>
                <div className="flex md:order-2">
                    <div className="hidden  md:flex">

                        <input type="text" id="search-navbar" className="block p-2 pl-3 w-full text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 sm:text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                        <div className="flex inset-y-0 left-0 items-center cursor-pointer bg-[#727CF4] px-2 rounded-r-lg">
                            <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" ></path></svg>
                        </div>
                        <div className="flex inset-y-0 left-0 items-center cursor-pointer bg-[#727CF4] px-2 rounded-lg ml-2 shadow-md">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                                <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className=" justify-between items-center w-full md:flex md:w-auto md:order-1" id="navbar-search">
                    <div className=" mt-3 flex md:hidden">
                        <input type="text" id="search-navbar" className="block p-2 pl-3 w-full text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 sm:text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="08/10/2022" />
                        <div className="flex inset-y-0 left-0 items-center px-2 cursor-pointer bg-[#727CF4] rounded-r-lg">
                            <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                        </div>
                        <div className="flex inset-y-0 left-0 items-center cursor-pointer bg-[#727CF4] px-2 rounded-lg ml-2 shadow-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                                <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>

                        </div>
                        {/* <div className='bg-black'> */}

                        {/* </div> */}
                    </div>
                    {/* <button onClick={handleNav}   className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button"></button> */}

                </div>
            </div>
        </div>
    )
}

export default Dashboard