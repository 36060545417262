import { combineReducers } from "redux";
import userReducer from "./userReducer";
import imageReducer from "./imageReducer";
import imagesReducer from "./imagesReducer";



const rootReducer = combineReducers({
    user: userReducer,
    imageData: imageReducer,
    images: imagesReducer
})
export default rootReducer