import React, { useRef } from 'react'
import Header from '../../../Components/Header/Header'
import storage from '../../../FirebaseFile/FirebaseFile'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { CircularProgress } from '@material-ui/core';

import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../../../Components/Dashboard/Dashboard';

function UploadImages() {

  const myRef = useRef('Upload Images')

  
  



  const [progress, setProgress] = useState(0);
  const [images, setImages] = useState([])
  const onDrop = useCallback(acceptedFiles => {
    let user_temp = [...images];
    for (var i = 0; i < acceptedFiles.length; i++) {
      user_temp.push({ status: "queue", file: acceptedFiles[i] })
    }
    
    setImages(user_temp)
    
  }, [images])

  const { acceptedFiles, getRootProps, getInputProps, fileRejections, } = useDropzone(
    {
      onDrop,
      multiple: true,
    }
  );
  const uploadFiles = async () => {
    for (var i = 0; i < images.length; i++) {
      if (images[i].status != "uploaded" || images[i].status != "uploading")
        await uploadSingleFile(i)
    }
  }

  const uploadSingleFile = async (index) => {


    updateStatus(index, "uploading")



    const storageRef = ref(storage, `/files/${uuidv4()}.${images[index].file.path.split('.').pop()}`);

    const uploadTask = uploadBytesResumable(storageRef, images[index].file);



    await uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setProgress(percent);
      },
      (err) => console.log(err),
      () => {
        // download url

        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          

          

          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/imageUpload`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              originalName: images[index].file.path,
              url: url,
              fileName: `${uuidv4()}.${images[index].file.path.split('.').pop()}`,
            }),
          })
          const response_data = await response.json()
          console.log(response_data)

          //console.log({originalName: response_data.file.originalName, imageUrl: response_data.file.ImageUrl, fileName: response_data.file.fileName})

         
          
          if (response_data.status==="ok") {
            console.log("okkkkkk")
           
            updateStatus(index, "uploaded")
            
          }

        });

      }
    );

  }
  const updateStatus = (index, status) => {
    let temp_imgs = [...images]
    temp_imgs[index].status = status
    setImages(temp_imgs)
  }
  



  return (
    <div className='bg-[#FAFBFE] w-full h-screen'>
      <Header ref={myRef}>
        <Dashboard/>
        <div className='bg-[#FAFBFE] w-full h-screen'>
          <div className='border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 max-w-[1240px] mx-auto'>
            <div className=" max-w-[1240px] mx-auto bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <div className="relative">
                <div className='flex justify-center'>
                  <div {...getRootProps()}
                  className='flex justify-center items-center bg-[#CBCBCB] border-4 border-[#555] border-dotted mt-10 h-[200px] w-[500px]'

                    >
                    <input {...getInputProps()} />

                    <p
                    >Drag 'n' drop some files here, or click to select files</p>

                  </div>

                </div>
                <div>
                  <button onClick={() => uploadFiles()} className='border border-[#696cff] text-[#727CF4] hover:text-white hover:bg-[#727CF4] rounded-md text-sm pt-1 pb-1 pl-2 pr-2 mt-16 mb-4'  >
                    Upload Files
                  </button>
                </div>
                <aside>
                  <h4>Files</h4>

                  <div className='flex flex-col' >


                    {images?.map((item, index) => {
                      console.log(item.file.path)
                      return (
                        
                          <div key={index} className='flex flex-row justify-between items-center' >
                            <div>
                              <li >
                                {item.file.name} - {item.file.size} bytes

                              </li>
                            </div>


                            <div>

                              {

                                item.status

                              }

                            </div>
                            {/* <Button variant="contained" onClick={() => deleteFile(item.file.name, index)} color="primary" style={{ marginTop: "15px" }}>
                                                Delete File
                                            </Button> */}



                            <div>
                              <CircularProgress variant="determinate" value={progress} />
                            </div>
                          </div>
                        
                      )

                    })}


                  </div>


                </aside>
              </div>
            </div>
          </div>
        </div>




        <div className='max-w-[1240px] mx-auto'>


        </div>




      </Header>
    </div>
  )
}

export default UploadImages