const initState = {
    images: []
}

const imagesReducer = (state = initState, action) => {
    switch (action.type) {
        case "Fetch_Images_Data":
            
            return {
                ...state,
                images: action.images
            };
           
     
        default:
            return state
    }
}
export default imagesReducer